import { SwiperOptions } from 'swiper';

export const OBJECT_FLOOR_LIMIT = 50;

// How many issues should be shown on element/item preview
export const ELEMENT_CARD_ISSUE_LIMIT = 3;

// Duration of 'upgarde now' message in ms
export const TRIAL_MESSAGE_DURATION = 30 * 1000; // 3 seconds

export const PDF_GENERATE_DELAY = 1000; // 1 second

export const COMMENT_SAVE_DEBOUNCE_DURATION = 500; // 0.5 seconds

export const FETCH_PDF_RETRY_DELAY = 10 * 1000; // 10 seconds
export const GENERATE_PDF_RETRY_DELAY = 5 * 1000; // 10 seconds
export const GENERATE_PDF_RETRY_DURATION = 30 * 1000; // 30 seconds

export const SNACKBAR_TIMEOUT_DURATION = 300 * 1000; // 8 seconds
export const ERROR_SNACKBAR_TIMEOUT_DURATION = 300 * 1000; // 12 seconds

export const AUTOLOGIN_RETY_TIMEOUT = 5000; // 5 seconds

export const BASIC_DATA_RETRY_DELAY = 60 * 1000; // 60 seconds

export const SHARED_ISSUE_SAME_LOG_DELAY = 1; // minutes
export const AD_VIEW_DELAY = 1; // minutes

export const OFFLINE_MESSAGE_DELAY = 15 * 1000; // 15 seconds

// Google map zoom for street view
export const MAP_ZOOM = 17;

export const SUBSCRIBE_SUCCESS_TIMEOUT = 10 * 1000;

// Make sure the scroll end is detected somewhat earlier
export const SCROLL_END_DETECT_MARGIN = 60; // px

// Pagination params, setting to low value may cause issues (due to scroll based pagination)
// TODO: These values aren't defaulted to when not specifying query params explicitly

export const PER_PAGE = 10;
export const STORE_MAX = 100;

// Max size for base64 generated images
// from locally uploaded files

// Since those are not currenly viewable by lightbox, FIXME?
// keeping images small makes sense to save space
export const MAX_LOCAL_IMAGE_WIDTH = 640; // px
export const MAX_LOCAL_IMAGE_HEIGHT = 480; // px

// Note: regexes are of two types
// TYPE type is used to restrict input while typing, i.e only numbers
// IS type is used to validate input on submit
export const INT_TYPE_REGEX = /^[0-9]*$/;

export const FLOAT_TYPE_REGEX = /^([0-9]|\.)*$/;

// Alt /^(\d|\+|-){0,20}$/
export const PHONE_TYPE_REGEX = /^[+]?((?:[()\- ]|\d)*)$/;

export const DPD_NUMBER_OF_FLOORS_REGEX = /^[0-9]{0,2}$/;
export const DPD_EWID_REGEX = /^\d{0,3}$/;
export const DPD_YEAR_REGEX = /^[0-9]{0,4}$/;

export const PROTOCOL_ROUTE_REGEX = /^\/protocol\/\d+\/?$/;
export const STREET_NUMBER_REGEX = /^(\d+\w{0,3})(-\d+\w{0,2})?$/;

export const MIN_PHONE_LENGTH = 8;

export const ISSUE_TYPE_EXCESSIVE = 'SM';
export const ISSUE_TYPE_NORMAL = 'LM';

export const ITEM_CATEGORY_KEY = 'S';
export const ITEM_CATEGORY_MANUAL = 'H';

export const DPD_TYPE_HOUSE = 'H';
export const DPD_TYPE_CONDO = 'A';

export const BUILDING_TYPE_COMMERICAL_CONDO = 'W';

export const SUBDPD_TYPE_GENERAL = 'All';
export const SUBDPD_TYPE_APARTMENT = 'Wo';
export const SUBDPD_TYPE_COMMERCIAL = 'Ge';
export const SUBDPD_TYPE_GRAGE = 'Ga';
export const SUBDPD_TYPE_STORAGE_AREA = 'SA';

export const ATTENDEE_TYPE_EXPERT = 'EX';
export const ATTENDEE_TYPE_LANDLORD = 'PO';
export const ATTENDEE_TYPE_MANAGER = 'MA';
export const ATTENDEE_TYPE_MOVING_IN = 'NT';
export const ATTENDEE_TYPE_MOVING_OUT = 'TE';

export const MAX_ATTENDEES_ALLOWED = 9;

export const TENANT_TYPE_MOVING_IN = 'MOVING_IN';
export const TENANT_TYPE_LIVING = 'CURR_LIVING';
export const TENANT_TYPE_MOVED_OUT = 'OLD_TENANT';

export const MIOP_USEAGE_OPERATION_TYPE = 'MIOP';

// Address types

export const LIVING_ADDRESS_TYPE = 'LADDRS';
export const BILLING_ADDRESS_TYPE = 'BADDRS';

// Media related types (med_type)

export const MEDIA_TYPE_VIDEO = 'VI';
export const MEDIA_TYPE_IMAGE = 'BI';

export const MEDIA_PROTOCOL_PDF_TYPE = 'PROTO_PDF';
export const MEDIA_PROFILE_PICTURE_TYPE = 'PP';

export const MEDIA_DOCUMENT_TYPE = 'DOC';
export const MEDIA_ATTACHMENT_TYPE = 'ATTACH_DOC';

export const ELECTRICITY_PROOF_TYPE = 'PROTOCOL_ELECTRICITY_PROOF';
export const WATER_PROOF_TYPE = 'PROTOCOL_WATER_PROOF';
export const GAS_PROOF_TYPE = 'PROTOCOL_GAS_PROOF';

export const PDF_LOGO_TYPE = 'PDF_LOGO';

export const OBJECT_ATTRIBUTE_TYPE_HASH: IHash<string> = {
    rent: 'RpM',
    net_rent: 'NR',
    extra_costs: 'NK',
    room_count: 'RC',
    floor_number: 'FL',
    object_area: 'M2'
};

// Media filetypes limits

export const ATTACHMENT_TYPES = '.png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx';
export const LOGO_TYPES = '.png,.jpg,.jpeg';

export const ISSUE_MEDIA_UPLOAD_TYPES = 'image/*,video/*';
export const DPD_MEDIA_UPLOAD_TYPES = 'image/*,video/*';

// Media size limits

export const AWS_UPLOAD_LIMIT = 100 * 1024 * 1024; // 100mb
export const AD_VIDEO_SIZE_LIMIT = 150 * 1024 * 1024; // 150mb

export const ATTACH_UPLOAD_LIMIT = 20 * 1024 * 1024; // 20mb
export const LOGO_UPLOAD_LIMIT = 5 * 1024 * 1024; // 5mb

// Media length limits

export const VIDEO_LENGTH_LIMIT = 30; // 30 seconds
export const AD_VIDEO_LENGTH_LIMIT = 5 * 60; // 5 minutes

export const DPD_UPLOAD_CONFIG = {
    totalLimit: 7,
    imageLimit: 5,
    videoLimit: 2,
    videoLengthLimit: AD_VIDEO_LENGTH_LIMIT,
    allowedFiletypes: DPD_MEDIA_UPLOAD_TYPES,
    sizeLimit: AD_VIDEO_SIZE_LIMIT
};

export const ISSUE_UPLOAD_CONFIG = {
    totalLimit: 3,
    allowedFiletypes: ISSUE_MEDIA_UPLOAD_TYPES
};

export const STORE_PAGE_INIT: IStoredPagination = {
    startPage: 0,
    page: 0,
    endPage: 0,
    hasNext: false,
    hasPrevious: false,
    direction: undefined
};

export const isHouseType = (type: string) =>
    type === DPD_TYPE_HOUSE || type === DPD_TYPE_CONDO;

export const ANIMATE_HEIGHT_PROPS = {
    initial: { height: 0 },
    animate: { height: 'auto' },
    exit: { height: 0 },
    style: { overflow: 'hidden' }
};

export const PASSWORD_MESSAGE_HASH: IHash<string> = {
    min: 'pass_too_short',
    max: 'pass_too_long',
    uppercase: 'pass_must_contain_uppercase',
    lowercase: 'pass_must_contain_lowercase',
    letters: 'pass_must_contain_letters',
    digit: 'pass_must_contain_digits',
    spaces: 'pass_must_not_contain_spaces'
};

// Note: might be a good idea to consider image lazy loading on Dashboard
export const SWIPER_DEFAULTS: SwiperOptions = {
    slidesPerView: 'auto',
    initialSlide: 0,
    resistanceRatio: 0.5,
    grabCursor: true,
    mousewheel: false
};

export const starterFeatures: IFeature[] = [
    {
        feature: 'USER',
        per_extra: '5',
        included: '1',
        unit: 'COUNT'
    },
    {
        feature: 'DOCUMENT',
        included: '0',
        per_extra: '5',
        unit: 'GB'
    },
    {
        feature: 'MIOP',
        included: '0',
        per_extra: '19',
        unit: 'COUNT'
    },
    {
        feature: 'ADVERTISEMENT',
        included: '0',
        per_extra: '15',
        unit: 'COUNT'
    },
    {
        feature: 'ISSUE',
        unlimited: true,
        per_extra: '0',
        included: '0',
        unit: 'COUNT'
    }
];

export const premiumFeatures: IFeature[] = [
    {
        feature: 'USER',
        per_extra: '5',
        included: '1',
        unit: 'COUNT'
    },
    {
        feature: 'DOCUMENT',
        included: '0',
        per_extra: '5',
        unit: 'GB'
    },
    {
        feature: 'MIOP',
        included: '0',
        per_extra: '15',
        unit: 'COUNT'
    },
    {
        feature: 'ADVERTISEMENT',
        included: '0',
        per_extra: '12',
        unit: 'COUNT'
    },
    {
        feature: 'ISSUE',
        included: '0',
        per_extra: '0',
        unlimited: true,
        unit: 'COUNT'
    }
];

export const proFeatures: IFeature[] = [
    {
        feature: 'USER',
        per_extra: '5',
        included: '2',
        unit: 'COUNT'
    },
    {
        feature: 'DOCUMENT',
        included: '0',
        per_extra: '5',
        unit: 'GB'
    },
    {
        feature: 'MIOP',
        included: '0',
        per_extra: '12',
        unit: 'COUNT'
    },
    {
        feature: 'ADVERTISEMENT',
        included: '0',
        per_extra: '5',
        unit: 'COUNT'
    },
    {
        feature: 'ISSUE',
        unlimited: true,
        included: '1',
        per_extra: '0',
        unit: 'COUNT'
    }
];

export const payAsYouGoPlan: IPackage = {
    id: -3,
    identifier: 'PAYG',
    opens_at: null,
    closes_at: null,
    active: false,
    base_price: '0.0',
    currency: 'CHF',
    period: 'M',
    features: premiumFeatures
};

export const landlordPlan: IPackage = {
    id: -2,
    identifier: 'LANDLORD',
    opens_at: null,
    closes_at: null,
    active: false,
    base_price: '19.0',
    currency: 'CHF',
    period: 'M',
    features: premiumFeatures
};

export const officePlan: IPackage = {
    id: -3,
    identifier: 'OFFICE',
    opens_at: null,
    closes_at: null,
    active: false,
    base_price: '49.0',
    currency: 'CHF',
    period: 'M',
    features: proFeatures
};

export const testPackages: IPackage[] = [
    payAsYouGoPlan,
    landlordPlan,
    officePlan
];

export const SUPPORTED_OBJECT_TEMPLATES = [
    DPD_TYPE_HOUSE,
    DPD_TYPE_CONDO,
    SUBDPD_TYPE_GENERAL,
    SUBDPD_TYPE_COMMERCIAL,
    SUBDPD_TYPE_APARTMENT,
    SUBDPD_TYPE_GRAGE,
    SUBDPD_TYPE_STORAGE_AREA
];

export const offlineItemTemplates = [
    {
        pk: "Eingangsschlüssel",
        name: "Entrance keys"
    },
    {
        pk: "Garagenschlüssel",
        name: "Garage keys"
    },
    {
        pk: "Kellerschlüssel",
        name: "Cellar keys"
    }
];

export const ISSUE_CREATION_OPTIONS = {
    'en': ["Spots", "Scratches", "Signs of use", "Drill holes" , "Discoloration", "Chipping", "Calcification", "Rust", "Lost", "Striations"],
    'de': ["Flecken", "Kratzer", "Gebrauchsspuren", "Bohrlöcher", "Verfärbung", "Absplitterung", "Verkalkung", "Rost", "Verloren", "Striemen"],
    'fr': ["Taches", "Rayures", "Usures", "Trous de percement", "Décoloration", "Éclats", "Calcification", "Rouille", "Perdu", "Marques"],
    'it': ["Macchie", "Graffi", "Segni di usura", "Fori da trapano", "Decolorazione", "Sfaldature", "Calcificazione", "Ruggine", "Perso", "Striature"],
}

export const PERSONA_DATA: {[key: string]: string} = {
    "LANDLORD": 'persona_description.landlord',
    "MANAGEMENT": 'persona_description.management',
    "EXPERT": 'persona_description.expert',
    "BIZ_DEV": 'persona_description.biz_dev',
    "RENTALS": 'persona_description.rentals'
}

export const CLEAN_ACTIONS = [
    'GET_OFFLINE_PROTOCOL_CHECKOUT_ID_REQUEST',
    'GET_OFFLINE_PROTOCOL_CHECKOUT_ID_SUCCESS',
    'GET_OFFLINE_PROTOCOL_CHECKOUT_ID_FAIL',
    'DISCARD_OFFLINE_PROTOCOL_STATE',
    'POST_OFFLINE_PROTOCOL_REQUEST',
    'POST_OFFLINE_PROTOCOL_SUCCESS',
    'POST_OFFLINE_PROTOCOL_FAIL',
    'PATCH_OFFLINE_PROTOCOL',
    'PATCH_OFFLINE_PROTOCOL_STEP',
    'PATCH_OFFLINE_PROTOCOL_METERS',
    'PATCH_OFFLINE_PROTOCOL_NOTES',
    'PATCH_OFFLINE_PROTOCOL_CLEANING',
    'PATCH_OFFLINE_PROTOCOL_ROOM',
    'PATCH_OFFLINE_PROTOCOL_ROOM_ELEMENT',
    'PATCH_OFFLINE_PROTOCOL_ITEMS_CHECK',
    'PATCH_OFFLINE_ATTENDEE_REQUEST',
    'CREATE_OFFLINE_PROTOCOL_ATTENDEES',
    'CREATE_OFFLINE_PROTOCOL_ROOM',
    'CREATE_OFFLINE_PROTOCOL_ELEMENT',
    'CREATE_OFFLINE_PROTOCOL_ITEM',
    'CREATE_OFFLINE_PROTOCOL_ISSUE',
    'UPDATE_OFFLINE_PROTOCOL_ATTENDEES',
    'UPDATE_OFFLINE_PROTOCOL_ROOM',
    'UPDATE_OFFLINE_PROTOCOL_ELEMENT',
    'UPDATE_OFFLINE_PROTOCOL_ITEM',
    'UPDATE_OFFLINE_PROTOCOL_ISSUE',
    'DELETE_OFFLINE_PROTOCOL_ATTENDEES',
    'DELETE_OFFLINE_PROTOCOL_ROOM',
    'DELETE_OFFLINE_PROTOCOL_ELEMENT',
    'DELETE_OFFLINE_PROTOCOL_ITEM',
    'DELETE_OFFLINE_PROTOCOL_ISSUE',
    'OFFLINE_PROTOCOL_ROOM_QUICKADD',
    'OFFLINE_PROTOCOL_POST_SIGNATURE_REQUEST',
    'OFFLINE_PROTOCOL_POST_SIGNATURE_SUCCESS',
    'OFFLINE_PROTOCOL_POST_SIGNATURE_FAIL',
    'CREATE_MEDIA_REQUEST',
    'SET_MEDIA_UPLOAD_PREVIEW',
    'CREATE_MEDIA_SUCCESS',
    'OFFLINE_PROTOCOL_POST_SIGNATURE_SUCCESS',
    'CREATE_MEDIA_FAIL',
    'OFFLINE_PROTOCOL_POST_SIGNATURE_FAIL'
];